/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

function OmniPackageDevicePage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>OMNI Device</h1>
          <h2>Introduction</h2>
          <p>
            OMNI is a three-in-one device that combines a DMS (Driver Monitor
            System), ADAS (Advanced Driver Assistance Systems), and DVR (Digital
            Video Recorder). This document introduces the package of content,
            hardware of OMNI and its spec.
          </p>
          <h2>Content</h2>
          <p>The product includes the following contents:</p>
          <li>Main Unit x 1</li>
          <li>DMC x 1/li</li>
          <li>DVR Rear Camera（DVR-R） x 1</li>
          <li>Main Power Cable x 1</li>
          <li>DVR-R Power Cable x 1</li>
          <li>DMC Power Cable x 1</li>
          <h2>Spec</h2>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th>Device</th>
                  <th>Item</th>
                  <th>Spec</th>
                  <th>Descriptions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan="5">Camera</td>
                  <td>DVR-F</td>
                  <td>SONY IMX307-1920*1080p30, FOV=140/124/64, MIPI</td>
                  <td>Forward facing wide FOV camera</td>
                </tr>
                <tr>
                  <td>DVR-C</td>
                  <td>SONY IMX307-1920*1080p30, FOV=140/124/64, MIPI</td>
                  <td>Cabin facing camera for passenger</td>
                </tr>
                <tr>
                  <td>DVR-R</td>
                  <td>SONY IMX307-1920*1080p30, FOV=140, AHD2MIPI, IP67</td>
                  <td>Rear facing camera, AHD2MIPI on-board</td>
                </tr>
                <tr>
                  <td>ADAS</td>
                  <td>SONY IMX307-1920*1080p30, FOV=40/22, MIPI</td>
                  <td>Forward facing narrow FOV camera</td>
                </tr>
                <tr>
                  <td>DMC</td>
                  <td>OV2735 - 1920*1080p30, FOV=52/28.66, USB2.0</td>
                  <td>Driver monitoring camera</td>
                </tr>
                <tr>
                  <td rowSpan="15">Main Unit</td>
                  <td>SOC</td>
                  <td>Dual CA53 w/ IPU, encoder, ISP</td>
                  <td>Main processor</td>
                </tr>
                <tr>
                  <td>DDR</td>
                  <td>DDR3 - 512MB</td>
                  <td>DRAM</td>
                </tr>
                <tr>
                  <td>Flash</td>
                  <td>nand 4Gb (512MB)</td>
                  <td>NVM</td>
                </tr>
                <tr>
                  <td>Panel</td>
                  <td>2&quot; color LCD - 480*360</td>
                  <td>Display</td>
                </tr>
                <tr>
                  <td>SuperCap</td>
                  <td>Series 10F*2</td>
                  <td>
                    Activate for file close when sudden ACC or battery OFF
                  </td>
                </tr>
                <tr>
                  <td>G-sensor</td>
                  <td>6-axis w/ gyro</td>
                  <td>Harsh event detection; Speed estimation</td>
                </tr>
                <tr>
                  <td>GPS</td>
                  <td>built in antenna</td>
                  <td>GPS/GLONASS</td>
                </tr>
                <tr>
                  <td>WIFI/BLE</td>
                  <td>dual mode - host & client</td>
                  <td>On-board option</td>
                </tr>
                <tr>
                  <td>LTE-4G</td>
                  <td>cat.4</td>
                  <td>On-board option</td>
                </tr>
                <tr>
                  <td>Micro-SD</td>
                  <td>Socket, up to 256GB</td>
                  <td>SD-card not included in the product</td>
                </tr>
                <tr>
                  <td>RS-232</td>
                  <td>Serial port for external device data exchange</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>UART</td>
                  <td>For G-mouse option</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Speaker</td>
                  <td>For event alarm or walkie talkie</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Mic</td>
                  <td>&nbsp;</td>
                  <td>For audio recording & walkie talkie</td>
                </tr>
                <tr>
                  <td>Button</td>
                  <td>Menu/up/down/enter</td>
                  <td>UI control</td>
                </tr>
                <tr>
                  <td rowSpan="4">DMC</td>
                  <td>Camera</td>
                  <td>OV2735 - 1920*1080p30, FOV=52/28.66, USB2.0</td>
                  <td>Driver monitoring camera</td>
                </tr>
                <tr>
                  <td>IR</td>
                  <td>940nm IR-LED * 2</td>
                  <td>100mA</td>
                </tr>
                <tr>
                  <td>Cover - black</td>
                  <td>
                    90% IR transparency; cover DMC, IR, but leave holes for
                    indicator
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Indicator</td>
                  <td>Event indicator *1; Face presence indicator*1</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td rowSpan="8">Kit</td>
                  <td>Main unit</td>
                  <td>
                    Computing, A/V encoder, GPS, G-sensor, speaker/mic, and LCD
                  </td>
                  <td>Installed in the center of windshield</td>
                </tr>
                <tr>
                  <td>On-board option</td>
                  <td>LTE, WIFI/BLE</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>DMS</td>
                  <td>DMC + IR/LED w/ PWM control via USB type-C</td>
                  <td>Installed in front of driver</td>
                </tr>
                <tr>
                  <td>DVR-R</td>
                  <td>Rear view camera module</td>
                  <td>Installed in rear bumper or car plate</td>
                </tr>
                <tr>
                  <td>Vibration motor</td>
                  <td>Option for event alarm</td>
                  <td>Installed behind the seat</td>
                </tr>
                <tr>
                  <td>Power cable</td>
                  <td>12V/24V input regulating to 5V/3A</td>
                  <td>Connect to ACC and battery</td>
                </tr>
                <tr>
                  <td>DVR-R cable</td>
                  <td>AHD - 5m</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>DMC cable</td>
                  <td>USB - 3m</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td rowSpan="2">General</td>
                  <td>Temperature</td>
                  <td>
                    Operating temperature -20 - 65C; -40-85C if without Supercap
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>IP</td>
                  <td>Main box & DMS: none; DVR-R: IP67</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OmniPackageDevicePage;
