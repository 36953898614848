import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from './i18n';
import './css/App.css';
import NavigationBar from './NavigationBar';
import OmniPage from './OmniPage';
import IntroductionPage from './IntroductionPage';

function MainPage({ user, signOutCallback }) {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const toggleUserDialog = () => {
    setIsDialogVisible(!isDialogVisible); // Directly toggle the state
  };

  const doNothing = () => {};

  return (
    <div>
      <div className="route-container">
        <div className="app-wrapper">
          <NavigationBar user={user} onMenuClick={toggleUserDialog} />
          <div className="page-wrapper app-main">
            <Routes>
              <Route path="/" element={<Navigate to="/overview" replace />} />
              <Route
                path="/overview"
                element={<IntroductionPage user={user} />}
              />
              <Route path="/omni/*" element={<OmniPage user={user} />} />
            </Routes>
          </div>
        </div>
      </div>
      {!isDialogVisible && <div className="layers-root" />}
      {isDialogVisible && (
        <div className="layers-root">
          <div>
            <div
              data-focus-guard="true"
              tabIndex="-1"
              style={{
                width: '1px',
                height: '0px',
                padding: '0px',
                overflow: 'hidden',
                position: 'fixed',
                top: '1px',
                left: '1px',
              }}
            />
            <div data-focus-lock-disabled="disabled">
              <div
                className="popover-body popover-body-mobile-enabled"
                id="popover-2"
                style={{
                  transitionDuration: '200ms',
                  opacity: 1,
                  transformOrigin: '97px bottom',
                  marginBottom: '8px',
                  transform: 'translate3d(5px, 791px, 0px) scale(1)',
                }}
              >
                <div
                  className="popover-arrow "
                  style={{
                    width: '0px',
                    height: '0px',
                    left: '97px',
                    bottom: '-4px',
                  }}
                />
                <div className="popover-inner">
                  <div className="menu-root user-dropdown">
                    <div className="menu-item user-name">
                      <div>{user.split('@')[0]}</div>
                      <div className="user-email">{user}</div>
                    </div>
                    <div className="menu-separator" />
                    {/* <a href="https://openai.com/policies" target="_blank" rel="noopener noreferrer" className="menu-item menu-item-selectable">Terms &amp; policies</a> */}
                    <div
                      className="menu-item menu-item-selectable"
                      role="button"
                      tabIndex={0} // Makes the div focusable
                      onClick={signOutCallback}
                      onKeyDown={doNothing}
                    >
                      {i18n.t('button_logout')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-focus-guard="true"
              tabIndex="-1"
              style={{
                width: '1px',
                height: '0px',
                padding: '0px',
                overflow: 'hidden',
                position: 'fixed',
                top: '1px',
                left: '1px',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

// Define prop types
MainPage.propTypes = {
  user: PropTypes.string.isRequired,
  signOutCallback: PropTypes.func.isRequired, // if `onSignIn` is a required function
};

export default MainPage;
