import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported
import DownloadTable from './DownloadTable';

const documents = [
  {
    name: 'OMNI Cloud Quick User Guide v1.0',
    description: 'The quick instruction of cloud features.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Cloud_Quick_User_guide_v1.0.pdf',
  },
  // ... more documents
];

function OmniCloudGuidePage() {
  useEffect(() => {
    return () => {};
  }, []);

  // Render function
  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>OMNI Cloud User Guide</h1>
          <p>
            You can download all of the user manual and guide related to cloud
            based fleet management system here.
          </p>
        </div>
      </div>
      {/* The table for download. */}
      <h2>Resources</h2>
      <DownloadTable documents={documents} />
    </div>
  );
}

export default OmniCloudGuidePage;
