import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './i18n/en.json';
import zhTW from './i18n/zh-TW.json';
import zhCN from './i18n/zh-CN.json';
import jp from './i18n/jp.json';

const language = localStorage.getItem('language')
  ? localStorage.getItem('language')
  : 'en';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en,
  },
  'zh-TW': {
    translation: zhTW,
  },
  'zh-CN': {
    translation: zhCN,
  },
  jp: {
    translation: jp,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
