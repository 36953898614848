import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

function OmniGpsPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>GPS Positioning</h1>
          <h2>Introduction</h2>
          <p>
            GPS Positioning systems in vehicles are crucial for efficient fleet
            management, offering precise location tracking and route
            optimization. These systems utilize satellite navigation technology
            to provide real-time data on vehicle location, enhancing operational
            efficiency and driver accountability.
          </p>
          <h2>Key Features and Functions</h2>
          <h3>Real-Time Location Tracking:</h3>
          <p>
            The Real-Time Location Tracking feature, empowered by GPS
            technology, is a cornerstone of modern fleet management. It provides
            fleet managers with the ability to monitor vehicle locations in real
            time, playing a critical role in optimizing route management and
            responding to dynamic on-road situations. Key aspects of this
            feature include:
          </p>
          <ul>
            <li>
              <strong>GPS-Based Vehicle Tracking:</strong> Utilizing GPS
              technology, the system offers live tracking of vehicle positions,
              giving fleet managers up-to-the-minute location information.
            </li>
            <li>
              <strong>Enhanced Route Management:</strong> Real-time location
              data enables more efficient route planning and adjustments,
              helping to reduce delays and improve overall fleet efficiency.
            </li>
            <li>
              <strong>Vital for Dynamic Dispatching:</strong> The immediate
              visibility of vehicle locations is invaluable for dynamic
              dispatching, allowing for quick response to changes or emergencies
              on the road.
            </li>
          </ul>
          <p>
            By employing Real-Time Location Tracking, fleet managers gain a
            comprehensive view of their fleet&apos;s movements, enhancing
            operational control and decision-making capabilities.
          </p>
          <h3>Geofencing:</h3>
          <p>
            Geofencing technology is a pivotal feature in our fleet management
            system, providing enhanced security and operational control. By
            creating virtual boundaries or perimeters, it allows fleet managers
            to set up and monitor specific geographic zones. This feature is
            crucial for maintaining the security of the fleet and optimizing
            operational efficiency. Key functionalities and benefits include:
          </p>
          <ul>
            <li>
              <strong>Virtual Perimeter Setup:</strong> Managers can define
              specific geographic areas as zones of interest, setting boundaries
              for where vehicles should or should not operate.
            </li>
            <li>
              <strong>Exit Alerts:</strong> The system automatically notifies
              managers whenever a vehicle exits these predefined geofenced
              zones, allowing for close monitoring of vehicle movements.
            </li>
            <li>
              <strong>Immediate Breach Notification:</strong> In cases where a
              vehicle crosses these boundaries without authorization, an alert
              is immediately sent to the manager&apos;s mobile device through
              our dedicated application, prevent the potential security issues.
            </li>
          </ul>
          <p>
            Implementing Geofencing technology in fleet management enables
            enhanced security measures and provides managers with greater
            oversight and control over their fleet operations.
          </p>
          <h3>Mileage Estimation:</h3>
          <p>
            The Mileage Estimation feature within our GPS positioning systems is
            crucial for accurately tracking and managing vehicle use. It
            calculates the distance traveled by each vehicle in the fleet over a
            specified period, using advanced algorithms and real-time GPS data.
            This feature is integral to various aspects of fleet management,
            such as fuel efficiency, maintenance planning, and cost management.
            Key aspects and benefits of Mileage Estimation include:
          </p>
          <ul>
            <li>
              <strong>Accurate Distance Calculation:</strong> Sophisticated
              algorithms provide precise mileage readings for each vehicle,
              ensuring that the distance traveled is accurately captured.
            </li>
            <li>
              <strong>Facilitates Fuel Management:</strong> By accurately
              estimating mileage, fleet managers can better manage fuel
              consumption, leading to more efficient fuel use and cost savings.
            </li>
            <li>
              <strong>Supports Maintenance Scheduling:</strong> Regular mileage
              updates help in scheduling timely maintenance, thus prolonging
              vehicle life and preventing costly repairs.
            </li>
            <li>
              <strong>Enhances Cost Accounting:</strong> Precise mileage data
              aids in effective cost accounting, allowing for more accurate
              budgeting and expense tracking.
            </li>
          </ul>
          <p>
            Mileage Estimation provides essential data that helps fleet managers
            optimize operational efficiency and control expenses, making it a
            valuable tool in comprehensive fleet management.
          </p>

          <h2>Settings</h2>
          <p>
            GPS features can be managed and customized through the&nbsp;
            <a href="https://cloud.mindtronicai.com">fleet management system</a>
            . Navigate to Settings for access to configuration options.
          </p>
          <h3>Geo-fencing:</h3>
          <p>
            You can define the permissible geographical boundaries for your
            vehicle using the geo-fencing settings.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OmniGpsPage;
