// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDs18vl2gC9OWa9eGTboME5Ek-hFdX0NM0',
  authDomain: 'mindtronicai-omni.firebaseapp.com',
  projectId: 'mindtronicai-omni',
  storageBucket: 'mindtronicai-omni.appspot.com',
  messagingSenderId: '239328127680',
  appId: '1:239328127680:web:7046a982e09264a61cdb25',
  measurementId: 'G-JV3E75513Y',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
const auth = getAuth(app);

export default auth;
