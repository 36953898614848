import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

function OmniFaceIdPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Face ID for Driver Authentication</h1>
          <h2>Introduction</h2>
          <p>
            Face ID systems in vehicles are advanced facial recognition
            technologies designed for driver identification and authentication.
            These systems utilize cameras and algorithms to accurately recognize
            and verify driver identities, enhancing vehicle security and
            personalization.
          </p>
          <h2>Key Features and Functions</h2>
          <h3>Driver Identification</h3>
          <p>
            The Driver Identification feature in our system leverages advanced
            facial recognition technology to enhance fleet management and
            safety. This system plays a critical role in accurately identifying
            drivers and monitoring their driving duration. Key functionalities
            and benefits of this feature include:
          </p>
          <ul>
            <li>
              <strong>Facial Recognition Technology:</strong> By transmitting
              facial features to the cloud, the system accurately identifies the
              driver at the start of each journey. This ensures that driving
              data is correctly attributed to the respective driver.
            </li>
            <li>
              <strong>Driving Duration Tracking:</strong> The system tracks the
              driving duration for each identified driver, aiding in compliance
              with working hours regulations and preventing driver fatigue.
            </li>
            <li>
              <strong>Incident Logging and Analysis:</strong> In the event of a
              dangerous occurrence, the system not only logs the incident but
              also captures the driver&apos;s identity. This facilitates a
              detailed analysis of the incident, offering insights into
              individual driver behavior and overall fleet safety.
            </li>
          </ul>
          <p>
            Implementing Driver Identification enhances the accuracy of driver
            monitoring and contributes significantly to the safety and
            efficiency of fleet operations.
          </p>
          <h3>Unknown Driver Detection:</h3>
          <p>
            The Unknown Driver Detection feature in our system is an essential
            component for enhancing vehicle security. Utilizing advanced facial
            recognition technology, this feature is designed to detect and
            respond to the presence of an unrecognized individual in the
            driver&apos;s seat. Key functionalities and benefits of this feature
            include:
          </p>
          <ul>
            <li>
              <strong>Advanced Facial Recognition:</strong> The system
              identifies when someone other than a registered and authorized
              user is attempting to operate the vehicle, ensuring strict access
              control.
            </li>
            <li>
              <strong>Immediate Alert System:</strong> Upon detecting an unknown
              driver, the system promptly notifies the vehicle owner or relevant
              security personnel. This immediate response is crucial for
              preventing unauthorized use or potential theft.
            </li>
            <li>
              <strong>Enhanced Vehicle Security:</strong> By ensuring that only
              designated drivers can access the vehicle&apos;s functionalities,
              this feature significantly bolsters the overall security of the
              vehicle.
            </li>
          </ul>
          <p>
            Implementing the Unknown Driver Detection feature is key to
            safeguarding vehicles against unauthorized access, thereby enhancing
            the security and integrity of fleet operations.
          </p>
          <h3>Driver Registration:</h3>
          <p>
            The Driver Registration feature is a key component in maintaining an
            up-to-date driver database in our system. It offers a seamless
            process for registering new drivers, especially when an unknown
            driver is detected. This feature simplifies the integration of new
            drivers into the fleet management system. Key steps and benefits of
            this feature include:
          </p>
          <ul>
            <li>
              <strong>Seamless Integration of New Drivers:</strong> When an
              unknown driver is detected, their Face ID and snapshot are
              automatically uploaded to the cloud. This facilitates the prompt
              registration of new drivers.
            </li>
            <li>
              <strong>Convenient Registration Process:</strong> Fleet managers
              can easily register new drivers through our{' '}
              <a href="https://cloud.mindtronicai.com">cloud service</a>. This
              is done by navigating to the &apos;Driver → Register&apos; section
              in the cloud service interface.
            </li>
            <li>
              <strong>Ensuring an Updated Driver Database:</strong> By enabling
              quick and easy registration of new drivers, this feature ensures
              that the fleet&apos;s driver database is always current and
              comprehensive, enhancing overall fleet management efficiency.
            </li>
          </ul>
          <p>
            The Driver Registration feature streamlines the process of adding
            new drivers to the system, ensuring that all drivers are properly
            documented and authorized for vehicle operation.
          </p>
          {/* <p>
            If an unknown driver is detected, it is likely that they are not yet
            registered in the system. Given that both the Face ID and the
            driver&apos;s snapshot have already been uploaded to the cloud, the
            fleet manager can conveniently register the driver directly through
            our &nbsp;
            <a href="https://cloud.mindtronicai.com">cloud service</a>. You can
            just go to Driver → Register of{' '}
            <a href="https://cloud.mindtronicai.com">cloud service</a> to
            register them
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default OmniFaceIdPage;
