import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

function OmniFleetSetupPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Setup my fleet</h1>
          <h2>Step 1: Configure the fleet</h2>
          <p>
            You can configure the fleet&apos;s notification settings and
            geo-fencing in our{' '}
            <a href="https://cloud.mindtronicai.com">
              fleet management cloud service
            </a>
            . In the notification settings, you have the option to configure
            which types of abnormal events trigger notifications to your mobile
            phone. Additionally, you can define the permissible geographical
            boundaries for your vehicle using the geo-fencing settings.
          </p>
          <h2>Step 2: Setup Drivers</h2>
          <p>
            This step involves registering a new driver into the fleet
            management system, enabling continuous tracking, analysis, and
            coaching of your fleet&apos;s drivers. The driver&apos;s face ID
            will be automatically upload to the server. You can just go to
            Driver → Register of our{' '}
            <a href="https://cloud.mindtronicai.com">
              fleet management cloud service
            </a>
            &nbsp;to fill the driver&apos;s information.
          </p>
          <h2>Step 3: Register driver account for mobile app</h2>
          <p>
            This step enables the management of driver clock in/out and
            dispatching. If these features are not required for your operations,
            you may skip this step. Currently we don&apos;t have an interface
            for the driver registration. Please contact Mindtronic AI&apos;
            support by sending <a href="mailto:info@mindtronicai.com">email</a>.
            We will provide this feature soon.
          </p>
          <h2>Next Steps</h2>
          <p>
            Congratulations on successfully completing the fleet setup! You are
            now ready to fully utilize the entire system. Typically, this marks
            the completion of the initial setup process. However, if you wish to
            update your system to the latest software version, we&apos;ve made
            it easy for you. Simply <a href="/omni/update">click here</a> to
            proceed with the update.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OmniFleetSetupPage;
