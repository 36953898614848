const IsPageAt = (page, exactMatch = true) => {
  if (exactMatch) {
    return window.location.pathname === page;
  }
  return window.location.pathname.includes(page);
};

const inOverview = () => IsPageAt('/overview');
const inOmniMenu = () => IsPageAt('/omni', false);

export { IsPageAt, inOmniMenu, inOverview };
