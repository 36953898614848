import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

function OmniDrivingBehaviorPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Driving Behavior Monitoring</h1>
          <h2>Introduction</h2>
          <p>
            Driving Behavior Monitoring systems are designed to identify against
            unsafe driving practices such as harsh acceleration, harsh braking,
            and harsh turning. These systems use g-sensor, gyro and algorithms
            to analyze driving patterns, thereby enhancing road safety and
            promoting responsible driving behavior.
          </p>
          <h2>Key Features and Functions</h2>
          <h3>Harsh Acceleration Detection</h3>
          <p>
            Harsh Acceleration Detection in our system is designed to identify
            and alert when a vehicle accelerates too quickly. This feature is
            crucial for promoting safe driving habits and reducing wear on the
            vehicle. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Acceleration Monitoring:</strong> The system continuously
              monitors the rate of acceleration. If a vehicle accelerates too
              rapidly, an alert is triggered, signaling potentially unsafe
              driving behavior.
            </li>
            <li>
              <strong>Safety and Maintenance:</strong> By detecting harsh
              acceleration, this feature helps in reducing the risk of accidents
              and vehicle wear, contributing to overall fleet safety and
              longevity.
            </li>
          </ul>
          <h3>Harsh Brake Detection</h3>
          <p>
            The Harsh Brake Detection feature of our system identifies instances
            of abrupt or excessive braking. This is important for ensuring
            driver safety and preserving the vehicle&apos;s braking system. Key
            aspects include:
          </p>
          <ul>
            <li>
              <strong>Brake Monitoring:</strong> The system detects when the
              vehicle experiences a sudden and intense decrease in speed,
              indicating harsh braking, and issues an alert to the driver.
            </li>
            <li>
              <strong>Preventive Safety:</strong> Monitoring harsh braking
              assists in preventing accidents and reducing brake wear, enhancing
              both safety and vehicle upkeep.
            </li>
          </ul>

          <h3>Harsh Turn Detection</h3>
          <p>
            Harsh Turn Detection in our system alerts drivers when they make
            abrupt or sharp turns. This feature is vital for promoting smoother
            driving and ensuring vehicle stability. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Turn Analysis:</strong> The system analyzes turning
              patterns and issues an alert when a turn is too sharp or executed
              too rapidly, which could pose a safety risk.
            </li>
            <li>
              <strong>Enhancing Stability:</strong> Detecting harsh turns helps
              in maintaining vehicle stability and control, reducing the risk of
              rollovers or loss of control.
            </li>
          </ul>

          <h3>Collision Detection</h3>
          <p>
            Collision Detection in our system is an essential safety feature
            that identifies when a vehicle has been involved in a collision. It
            plays a crucial role in immediate response and post-incident
            analysis. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Impact Monitoring:</strong> The system detects when a
              vehicle experiences a sudden force indicating a collision and
              promptly alerts the necessary channels for a quick response.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OmniDrivingBehaviorPage;
