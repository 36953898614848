import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

function OmniVideoTelematicsPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Video Telematics</h1>
          <h2>Introduction</h2>
          <p>
            Video Telematics systems in vehicles combine video data and vehicle
            telemetry to provide comprehensive insights into fleet operations.
            These systems integrate advanced video recording, streaming, and
            communication technologies to enhance fleet management, safety, and
            communication. They are instrumental in providing real-time visual
            insights and facilitating effective communication between fleet
            managers and drivers. Managers can access this feature via the
            mobile app by navigating to &apos;Fleet,&apos; then selecting the
            center tab button. From there, simply press on the online vehicle
            you wish to access and activate the video/audio switch.
          </p>
          <h2>Key Features and Functions</h2>
          <h3>On-Demand Streaming:</h3>
          <p>
            The On-Demand Streaming feature in our fleet management system
            offers a dynamic tool for real-time monitoring and situational
            awareness. This feature allows fleet managers to access live video
            feeds from OMNI cameras installed in vehicles. It is especially
            crucial for observing road conditions, driver behavior, and
            providing immediate visual context during incidents. Key aspects of
            this feature include:
          </p>
          <ul>
            <li>
              <strong>Remote Live Video Access:</strong> Fleet managers can
              remotely view live feeds from the vehicle’s cameras, enabling them
              to monitor situations as they unfold in real-time.
            </li>
            <li>
              <strong>Comprehensive Coverage:</strong> The streaming service
              supports three channels - front, rear, and cabin - ensuring a
              complete and multi-dimensional view of both the road and the
              vehicle’s interior.
            </li>
            <li>
              <strong>Invaluable During Incidents:</strong> In the event of an
              incident, having immediate access to video feeds provides fleet
              managers with crucial visual context, aiding in swift and informed
              decision-making.
            </li>
          </ul>
          <p>
            By leveraging the On-Demand Streaming feature, fleet managers gain
            enhanced oversight and the ability to respond effectively to on-road
            events, thereby improving the overall safety and efficiency of fleet
            operations.
          </p>
          <h3>Walkie-Talkie:</h3>
          <p>
            The Walkie-Talkie feature in our system is a vital communication
            tool that bridges the gap between fleet managers and drivers. It
            facilitates instant voice communication, allowing managers to
            quickly convey important messages directly to drivers. This feature
            is particularly useful for urgent communications or when text-based
            messaging is not feasible. Key functionalities and advantages of
            this feature include:
          </p>
          <ul>
            <li>
              <strong>Instant Voice Communication:</strong> Managers can
              establish a direct voice communication session with drivers using
              the OMNI device and the mobile app. This immediate connection is
              essential for conveying urgent or detailed instructions.
            </li>
            <li>
              <strong>Simple Message Transmission:</strong> To send a message,
              the manager presses and holds the microphone button on the mobile
              app to record their voice and releases the button to transmit the
              message. This push-to-talk mechanism ensures ease of use and
              clarity in communication.
            </li>
            <li>
              <strong>Intentional Communication:</strong> The design of the
              Walkie-Talkie feature ensures that only intentional voice
              communications are sent. This prevents the accidental transmission
              of background noise or unintended audio, maintaining the focus and
              clarity of the messages.
            </li>
          </ul>
          <p>
            Implementing the Walkie-Talkie feature enhances the effectiveness of
            communication between fleet managers and drivers, ensuring that
            critical information is relayed promptly and efficiently.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OmniVideoTelematicsPage;
