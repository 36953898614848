import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

import DownloadTable from './DownloadTable';

const documents = [
  {
    name: 'OMNI App Quick User Guide v1.0',
    description: 'The quick instruction of app features.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_App_Quick_User_guide_v1.0.pdf',
  },
  // ... more documents
];

function OmniAppGuidePage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>OMNI App User Guide</h1>
          <p>
            You can download all of the user manual and guide related to mobile
            app here.
          </p>
        </div>
      </div>

      {/* The table for download. */}
      <h2>Resources</h2>
      <DownloadTable documents={documents} />
    </div>
  );
}

export default OmniAppGuidePage;
