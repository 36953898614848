import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import auth from './firebase';
import SignIn from './SignIn';
import MainPage from './MainPage';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    // console.log(user.email);
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const signOutUser = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem('user');
        setUser(null);
      })
      .catch((error) => {
        console.error('Error signing out', error);
      });
  };

  return (
    <Router>
      <div>
        {user ? (
          <MainPage user={user.email} signOutCallback={signOutUser} />
        ) : (
          <SignIn
            onSignIn={() => setUser(JSON.parse(localStorage.getItem('user')))}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
