import React, { useEffect } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import './css/ContentPage.css'; // Ensure this is correctly imported
import OmniOverviewPage from './OmniOverviewPage';
import OmniInstallationPage from './OmniInstallationPage';
import OmniUpdatePage from './OmniUpdatePage';
import OmniRegisterPage from './OmniRegisterPage';
import OmniFleetSetupPage from './OmniFleetSetupPage';
import OmniManualPage from './OmniManualPage';
import OmniAppGuidePage from './OmniAppGuidePage';
import OmniCloudGuidePage from './OmniCloudGuidePage';
import OmniDmsPage from './OmniDmsPage';
import OmniAdasPage from './OmniAdasPage';
import OmniDvrPage from './OmniDvrPage';
import OmniDrivingBehaviorPage from './OmniDrivingBehaviorPage';
import OmniFaceIdPage from './OmniFaceIdPage';
import OmniGpsPage from './OmniGpsPage';
import OmniVideoTelematicsPage from './OmniVideoTelematicsPage';
import OmniCloudPage from './OmniCloudPage';
import OmniPackageDevicePage from './OmniPackageDevicePage';
import OmniPackageCloudPage from './OmniPackageCloudPage';
import OmniPackageAppPage from './OmniPackageAppPage';
import OmniReleaseNotePage from './OmniReleaseNotePage';
import OmniFaqPage from './OmniFaqPage';

function OmniPage({ user }) {
  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <div className="page-body full-width flush docs-page">
      <div className="docs-nav">
        <div className="side-nav">
          <div className="side-nav-section">
            <div className="side-nav-header subheading">QuickStart</div>
            <Link to="/omni/overview" className="scroll-link side-nav-item">
              Overview
            </Link>
            <Link to="/omni/installation" className="scroll-link side-nav-item">
              Vehicle Installation
            </Link>
            <Link to="/omni/register" className="scroll-link side-nav-item">
              Register my OMNI device
            </Link>
            <Link to="/omni/setup" className="scroll-link side-nav-item">
              Setup my fleet
            </Link>
            <Link to="/omni/update" className="scroll-link side-nav-item">
              Update software
            </Link>
          </div>
          <div className="side-nav-section">
            <div className="side-nav-header subheading">Package</div>
            <Link
              to="/omni/package-device"
              className="scroll-link side-nav-item"
            >
              OMNI Device
            </Link>
            <Link
              to="/omni/package-cloud"
              className="scroll-link side-nav-item"
            >
              Cloud Service
            </Link>
            <Link to="/omni/package-app" className="scroll-link side-nav-item">
              Mobile Apps
            </Link>
          </div>
          <div className="side-nav-section">
            <div className="side-nav-header subheading">Function</div>
            <Link to="/omni/dms" className="scroll-link side-nav-item">
              DMS
            </Link>
            <Link to="/omni/adas" className="scroll-link side-nav-item">
              ADAS
            </Link>
            <Link
              to="/omni/driving-behavior"
              className="scroll-link side-nav-item"
            >
              Driving Behavior
            </Link>
            <Link to="/omni/faceid" className="scroll-link side-nav-item">
              Face ID
            </Link>
            <Link to="/omni/gps" className="scroll-link side-nav-item">
              GPS
            </Link>
            <Link to="/omni/dvr" className="scroll-link side-nav-item">
              DVR
            </Link>
            <Link
              to="/omni/video-telematics"
              className="scroll-link side-nav-item"
            >
              Video Telematics
            </Link>
            <Link to="/omni/cloud" className="scroll-link side-nav-item">
              Cloud
            </Link>
          </div>
          <div className="side-nav-section">
            <div className="side-nav-header subheading">Support</div>
            <Link to="/omni/manual" className="scroll-link side-nav-item">
              User Manual
            </Link>
            <Link to="/omni/app-guide" className="scroll-link side-nav-item">
              OMNI Apps User Guide
            </Link>
            <Link to="/omni/cloud-guide" className="scroll-link side-nav-item">
              OMNI Cloud User Guide
            </Link>
          </div>
          <div className="side-nav-section">
            <div className="side-nav-header subheading">Others</div>
            <Link to="/omni/release-note" className="scroll-link side-nav-item">
              Release Note
            </Link>
            <Link to="/omni/faq" className="scroll-link side-nav-item">
              FAQ
            </Link>
          </div>
        </div>
      </div>
      <div className="documentPageContainer">
        <Routes>
          <Route path="/overview" element={<OmniOverviewPage user={user} />} />
          <Route path="/update" element={<OmniUpdatePage />} />
          <Route path="/installation" element={<OmniInstallationPage />} />
          <Route path="/register" element={<OmniRegisterPage />} />
          <Route path="/setup" element={<OmniFleetSetupPage />} />
          <Route path="/package-device" element={<OmniPackageDevicePage />} />
          <Route path="/package-cloud" element={<OmniPackageCloudPage />} />
          <Route path="/package-app" element={<OmniPackageAppPage />} />
          <Route path="/dms" element={<OmniDmsPage />} />
          <Route path="/adas" element={<OmniAdasPage />} />
          <Route path="/dvr" element={<OmniDvrPage />} />
          <Route path="/faceid" element={<OmniFaceIdPage />} />
          <Route path="/gps" element={<OmniGpsPage />} />
          <Route
            path="/driving-behavior"
            element={<OmniDrivingBehaviorPage />}
          />
          <Route
            path="/video-telematics"
            element={<OmniVideoTelematicsPage />}
          />
          <Route path="/cloud" element={<OmniCloudPage />} />
          <Route path="/manual" element={<OmniManualPage />} />
          <Route path="/app-guide" element={<OmniAppGuidePage />} />
          <Route path="/cloud-guide" element={<OmniCloudGuidePage />} />
          <Route path="/release-note" element={<OmniReleaseNotePage />} />
          <Route path="/faq" element={<OmniFaqPage />} />
        </Routes>
      </div>
    </div>
  );
}

// Define prop types
OmniPage.propTypes = {
  user: PropTypes.string.isRequired,
};

export default OmniPage;
