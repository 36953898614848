import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported
import NoticeWidget from './NoticeWidget';

function OmniPackageCloudPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Cloud Service</h1>
          <h2>Introduction</h2>
          <p>
            The Cloud-Based Fleet Management System is a comprehensive platform
            designed to enhance fleet operations through real-time monitoring,
            data analysis, and efficient management of resources. This system
            leverages cloud technology to offer a centralized view of fleet
            activities, driver behavior, and vehicle performance.
          </p>
          <p>
            All features of the Cloud-Based Fleet Management System can be
            managed and customized via the&nbsp;
            <a href="https://cloud.mindtronicai.com">online platform</a>.
          </p>

          <h2>Key Features and Functions</h2>
          <h3>Fleet Management:</h3>
          <p>
            Our Fleet Management system offers a detailed and dynamic overview
            of your entire fleet, providing essential insights and operational
            statuses in real-time. This feature is designed to give you a
            complete snapshot of your fleet&apos;s activities, ensuring
            effective management and oversight. Key functionalities include:
          </p>
          <ul>
            <li>
              <strong>Driver&apos;s Working Status:</strong> Keep track of each
              driver&apos;s working status, including hours on the road, breaks,
              and periods of inactivity. This helps in ensuring compliance with
              working hour regulations and maintaining driver well-being.
            </li>
            <li>
              <strong>Headlines - Abnormal Event Detection:</strong> Receive
              instant alerts and headlines for abnormal events such as driver
              idling, detection of an unknown driver, vehicles moving out of
              geo-fenced areas, and collision occurrences. This proactive alert
              system is designed to inform the manager of these abnormal
              conditions, prompting them to take appropriate actions swiftly.
            </li>
            <li>
              <strong>On-Duty Driver/Vehicle Overview:</strong> Get a
              comprehensive view of all drivers and vehicles that are currently
              on-duty. This overview includes current location, status, and
              other critical real-time information, ensuring effective
              coordination and deployment of your fleet resources.
            </li>
            <li>
              <strong>Driving Quality Trend:</strong> Monitor and analyze trends
              in driving quality over time. This feature helps in identifying
              patterns in driver behavior, allowing for targeted improvements
              and training.
            </li>
          </ul>
          <p>
            By leveraging these features, our Fleet Management system enhances
            the efficiency, safety, and overall performance of your fleet
            operations.
          </p>
          <h3>Driver Management:</h3>
          <p>
            Our Driver Management system provides a comprehensive platform for
            overseeing and optimizing your driver workforce. This system is
            integral for maintaining efficient and safe operations. Key features
            include:
          </p>
          <ul>
            <li>
              <strong>Complete Driver List:</strong> Access a detailed and
              up-to-date list of all drivers in your fleet. This list includes
              essential information about each driver, aiding in efficient
              management and coordination.
            </li>
            <li>
              <strong>Monitor On-Duty Driver Status:</strong> Keep real-time
              track of on-duty drivers. This monitoring helps in understanding
              their current status, location, and activity, ensuring timely and
              effective operations.
            </li>
            <li>
              <strong>Analyze Performance Statistics:</strong> Evaluate driver
              performance through comprehensive statistics. This analysis aids
              in identifying areas of strength and improvement, guiding training
              and development efforts.
            </li>
            <li>
              <strong>Review Historical Trajectories:</strong> Examine the
              historical routes and activities of your drivers. This review
              helps in understanding past behaviors and patterns, contributing
              to strategic planning and decision-making.
            </li>
            <li>
              <strong>Monitor Working Hours:</strong> Track and analyze driver
              working hours to ensure they are not overwhelmed or underutilized.
              This monitoring helps maintain a balance, preventing driver
              fatigue while optimizing workload distribution.
            </li>
          </ul>
          <p>
            The Driver Management system is designed to empower fleet managers
            with the tools and insights needed for high-level supervision and
            improvement of driver-related aspects of fleet operations.
          </p>
          <h3>Vehicle Management:</h3>
          <p>
            The Vehicle Management system is designed to provide a comprehensive
            overview and effective control over your fleet&apos;s vehicles. It
            plays a crucial role in optimizing fleet maintenance and usage. Key
            features include:
          </p>
          <ul>
            <li>
              <strong>Comprehensive Vehicle List:</strong> View a complete list
              of all vehicles within your fleet. This list provides detailed
              information on each vehicle, aiding in effective fleet management.
            </li>
            <li>
              <strong>Monitor On-Duty Vehicle Status:</strong> Track the status
              of vehicles that are currently on-duty. Monitoring includes
              real-time updates on location, activity, and operational status,
              ensuring efficient fleet deployment.
            </li>
            <li>
              <strong>Analyze Performance Statistics:</strong> Assess vehicle
              performance using detailed statistics. This analysis helps in
              identifying maintenance needs and operational efficiencies,
              guiding decision-making for fleet optimization.
            </li>
            <li>
              <strong>Track Historical Trajectories:</strong> Review past routes
              and usage patterns of your vehicles. Historical tracking aids in
              understanding vehicle utilization and can inform future fleet
              strategies.
            </li>
            <li>
              <strong>Fuel Analyzing:</strong> Evaluate fuel usage while
              normalizing for factors such as mileage and driving time. This
              analysis provides insights into fuel efficiency, helping in
              optimizing fuel consumption and reducing operational costs.
            </li>
            <li>
              <strong>Monitor Operation Time:</strong> Keep track of the
              operational hours of each vehicle to determine if they are being
              overused or underutilized. This monitoring ensures a balanced
              utilization of the fleet, contributing to both the longevity of
              the vehicles and operational efficiency.
            </li>
          </ul>
          <p>
            By utilizing these features, our Vehicle Management system enhances
            the operational efficiency, safety, and longevity of your fleet
            vehicles.
          </p>
          <NoticeWidget>
            To effectively utilize the fuel analyzing feature, it is essential
            to upload fuel data each time a vehicle is refueled. This consistent
            data input ensures accurate analysis and optimization of fuel usage
            across the fleet.
          </NoticeWidget>
          <h3>Event Monitoring:</h3>
          <p>
            Our Event Monitoring system is a pivotal tool for enhancing fleet
            safety and efficiency. It provides in-depth insights through the
            analysis of various events captured by the DMS (Driver Monitoring
            System), ADAS (Advanced Driver Assistance Systems), and driving
            behavior sensors. Key features of this system include:
          </p>
          <ul>
            <li>
              <strong>Access Event Lists:</strong> Browse through comprehensive
              lists of events recorded by DMS, ADAS, and driver behavior. This
              feature allows for a holistic view of all recorded events.
            </li>
            <li>
              <strong>Playback Event Videos:</strong> Review and play back event
              videos for detailed analysis. This functionality is crucial for
              understanding the context and details of each event.
            </li>
            <li>
              <strong>Conduct Thorough Event Analysis:</strong> Dive deep into
              each event with comprehensive data analysis. We log sensor data
              from the OMNI device and DMS indicators, creating detailed graphs
              to visualize this information. An interactive indicator is placed
              on these graphs, allowing users to synchronize and play the
              associated event clips. This integrated approach enables users to
              examine the event in conjunction with the corresponding sensor
              data, providing valuable insights into fleet safety and
              operational efficiency and aiding in proactive decision-making.
            </li>
          </ul>
          <p>
            The Event Monitoring system empowers fleet managers with critical
            information and analytics, facilitating effective strategies for
            improving fleet safety and performance.
          </p>
          <h3>Dispatch System:</h3>
          <p>
            Our Dispatch System is designed to streamline your logistics
            operations, ensuring efficient cargo dispatching, assignment, and
            tracking for timely deliveries. This system plays a critical role in
            enhancing the efficiency of your logistical processes. Key features
            include:
          </p>
          <ul>
            <li>
              <strong>Cargo Dispatching Assignment:</strong> Efficiently manage
              and assign cargo dispatching tasks. This feature allows for
              organized planning and distribution of cargo, ensuring that each
              assignment is handled effectively.
            </li>
            <li>
              <strong>Real-Time Tracking:</strong> Keep track of dispatched
              cargo in real-time. This functionality provides continuous updates
              on cargo status and location, ensuring transparency and timely
              updates throughout the delivery process.
            </li>
            <li>
              <strong>Streamlined Logistics Management:</strong> Utilize our
              comprehensive system to coordinate and optimize all aspects of
              your logistics operations. This integration results in a more
              streamlined process, from dispatching to delivery.
            </li>
          </ul>
          <p>
            By leveraging the capabilities of our Dispatch System, you can
            enhance operational efficiency, reduce delays, and ensure the
            punctuality and reliability of your deliveries.
          </p>
          <h3>Driver Coaching:</h3>
          <p>
            Our Driver Coaching system is an innovative approach to enhancing
            driving quality within your fleet. By utilizing a tailored issue
            ticket system, it focuses on providing constructive feedback and
            personalized coaching to drivers, based on their specific
            performance metrics. Key features of this system include:
          </p>
          <ul>
            <li>
              <strong>Issue Ticket System:</strong> Implement a structured
              approach to address driving issues. This system allows for the
              identification and documentation of specific areas where a driver
              may need improvement.
            </li>
            <li>
              <strong>Targeted Feedback:</strong> Provide drivers with specific,
              actionable feedback based on their performance. This personalized
              approach helps in addressing individual needs and promoting better
              driving practices.
            </li>
            <li>
              <strong>Personalized Coaching:</strong> Offer tailored coaching
              sessions to drivers, focusing on their unique challenges and
              performance metrics. This one-on-one coaching is instrumental in
              developing skills and enhancing overall driving quality.
            </li>
          </ul>
          <p>
            The Driver Coaching system is a crucial tool in our commitment to
            improving safety and efficiency in fleet operations, ensuring that
            drivers are supported and guided towards excellence in their roles.
          </p>
        </div>
      </div>
    </div>
  );
}
export default OmniPackageCloudPage;
