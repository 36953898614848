import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

function OmniDvrPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>DVR (Driver Video Recorder)</h1>
          <h2>Introduction</h2>
          <p>
            The Driver Video Recorder (DVR) system is a sophisticated recording
            technology designed to enhance vehicle safety and provide accurate
            documentation of road incidents. It utilizes advanced cameras and
            storage systems to capture continuous video footage of both the
            vehicle’s exterior and interior.
          </p>
          <h2>Key Features and Functions</h2>
          <h3>Continuous Recording:</h3>
          <p>
            DVR systems offer continuous video recording, ensuring that all
            aspects of the journey are captured. This feature is essential for
            accident analysis and insurance purposes.
          </p>
          <h3>Event-Triggered Recording:</h3>
          <p>
            In the event of an incident, such as sudden braking or a collision,
            the DVR automatically saves the relevant footage, providing critical
            evidence for post-incident analysis.
          </p>
          <h3>Night Vision:</h3>
          <p>
            Equipped with night vision capabilities, the DVR can record clear
            footage in low-light conditions, ensuring round-the-clock vehicle
            monitoring.
          </p>
          <h2>Settings</h2>
          <p>
            DVR settings can be customized via the OMNI system. Navigate to OMNI
            → Main Menu → DVR Settings for adjustments.
          </p>
          <h3>Enabling:</h3>
          <p>
            You possess the flexibility to activate or deactivate recording for
            each specific type of camera.
          </p>
        </div>
      </div>
    </div>
  );
}
export default OmniDvrPage;
