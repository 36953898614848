import React, { useEffect } from 'react';
import NoticeWidget from './NoticeWidget';
import './css/Notice.css'; // Ensure this is correctly imported
import './css/ContentPage.css'; // Ensure this is correctly imported

import DownloadTable from './DownloadTable';

const documents = [
  {
    name: 'OMNI Software MAI.ES.23.1224.1',
    description: 'The software updating file.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/SigmastarUpgradeSD.bin',
  },
  // ... more documents
];

function OmniUpdatePage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Update Software</h1>
          <p>
            There are two methods available for updating the OMNI device:
            Over-the-Air (OTA) updates and manual updates.
          </p>
          <h3>By OTA:</h3>
          <p>
            Mindtronic AI will provide substantial software updates via
            Over-The-Air (OTA) technology. All you need to do is press
            &apos;Update&apos; when the OTA update request dialog appears; Just
            follow the instructions to update the device.{' '}
          </p>
          <NoticeWidget>
            Please make sure the Internet is connected.
          </NoticeWidget>
          <h3>By Manual:</h3>
          <p>
            If your OMNI device doesn&apos;t have connectivity ability, you can
            also update the software manually.
          </p>
          <ol>
            <li>
              Download the&nbsp;
              <a href="https://api.cloud.mindtronicai.com:7001/download/SigmastarUpgradeSD.bin">
                Latest Update File
              </a>
              &nbsp; in resource table.
            </li>
            <li>Put the update file in the root of the SD card.</li>
            <li>Insert the SD card to OMNI</li>
            <li>Reboot the device.</li>
            <li>The update will start automatically.</li>
          </ol>
          {/* The table for download. */}
          <h2>Resources</h2>
          <DownloadTable documents={documents} />
        </div>
      </div>
    </div>
  );
}

export default OmniUpdatePage;
