import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import auth from './firebase';
import './css/SignIn.css'; // Ensure this is correctly imported

function SignIn({ onSignIn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Add this line

  const signInWithEmail = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.user));
        onSignIn();
      })
      .catch((err) => {
        console.error('Error signing in', err);
        setError('Login failed: Invalid account or password.'); // Set error message
      });
  };

  const resetPassword = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('Password reset email sent. Check your inbox!');
      })
      .catch((err) => {
        console.error('Error sending reset email:', err.message);
        setError('Reset failed: Invalid account.'); // Set error message
      });
  };

  const handleResetPasswordClick = (e) => {
    if (e.key === 'Enter') {
      resetPassword();
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', overflowX: 'hidden' }}>
      <div className="container">
        <img
          src="/images/MAI_logoOnly_smooth.png"
          alt="Company Logo"
          className="logo"
        />
        <h1 className="title">Mindtronic AI Product Portal</h1>
        <form onSubmit={signInWithEmail} className="form">
          <label htmlFor="email" className="input-label">
            Account
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="input-field"
            />
          </label>
          <label htmlFor="password" className="input-label">
            Password
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="input-field"
            />
          </label>
          <button type="submit" className="submit-button">
            Sign In
          </button>
          <div className="forgot-password-link">
            <span
              onClick={resetPassword}
              onKeyDown={handleResetPasswordClick} // Add a keyboard event listener
              tabIndex={0} // Make the element focusable
              role="button" // Indicate that it's interactive
            >
              Forgot Password?
            </span>
          </div>
          <div className="error-message">
            {error || <>&nbsp;</>}{' '}
            {/* This will render a non-breaking space when there's no error */}
          </div>
        </form>
      </div>

      {/* Right Part - Image and Text Overlay */}
      <div
        className="image-container"
        style={{
          background: 'url(/images/signInImage.png) center / cover no-repeat',
        }}
      >
        {' '}
        <div className="image-overlay-column">
          <div className="image-slogan-overlay">
            The Road to your Innovation
          </div>
          <div className="image-overlay">
            Find quick guides, detailed manuals, and software downloads for
            products of the Mindtronic AI.
          </div>
        </div>
        <div className="image-background">{/* Image goes here */}</div>
      </div>
    </div>
  );
}

// Define prop types
SignIn.propTypes = {
  onSignIn: PropTypes.func.isRequired, // if `onSignIn` is a required function
};

export default SignIn;
